@import '../../styles/assets/css/main.css';

.title-editor {
  width: 100%;
  color: var(--genics-dark-blue);
}

.title-editor-text {
  padding: 0 !important;
  font-weight: 900;
  font-size: 3.5em;
  font-family: 'Arial', sans-serif;
  margin-bottom: 0.5em;
  width: 100%;
}
