.editor-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  margin: 0;
  width: 90%; /* Increase to utilize more screen width */
  max-width: 1400px; /* Increase max width */
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1200px; /* Optional: Adjust max width as needed */
  padding: 0px;
}

.select-skill {
  flex: 1;
  min-width: 300px;
  margin: 1px;
  padding: 0px;
}

.create-skill {
  flex: 1;
  min-width: 300px;
  margin: 30px;
}

.editor-container {
  width: 100%;
  max-width: 1200px; /* Increase max width */
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px #ccc;
  border-radius: 10px;
}

.header-container {
  height: 100px;
  display: flex;
  justify-content: space-between; /* Push h4 to the left, button to the right */
  align-items: center;
  width: 100%; /* Ensure the header takes the full width of the container */
  margin-bottom: 10px; /* Optional: Add space between header and editor */
}

/* CKEditor Styling */
.ck-editor__editable {
  min-height: 500px;
  width: 100% !important; /* Force full width for the editable area */
  max-width: 100% !important; /* Remove any max-width constraints */
  box-sizing: border-box;
}

.ck-content {
  width: 100% !important; /* Ensure content area fills the container */
  height: 100%; /* Full height for the editor content */
  padding: 10px; /* Padding inside the editor */
  overflow-y: auto; /* Scrollable content if it overflows */
}

.ck-rounded-corners {
  width: 100% !important; /* Apply full width to any rounded corner styling */
  max-width: 100% !important; /* Remove any max-width constraints */
}

.ck-editor__main {
  width: 100% !important; /* Apply full width to main editor area */
}


.right-button {
  padding: 6px 12px;
  background-color: #590D82;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px; /* Adjust the max-width as needed */
  max-height: 50px;  /* Adjust the max-height as needed */
}

.right-button:hover {
  background-color: #cb96e8;
}

.right-button:disabled {
  background-color: gray; /* Color when disabled */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.layout-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.editor-content {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ccc;
}

.header-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.widget-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 250px; /* Adjust width as needed */
  padding: 20px;
  border-left: 1px solid #ccc;
}
