/* Component Specific Styling */

/* Footer Styles */
.footer {
  text-align: center;
  color: var(--genics-purple-fade);
}

/* Banner Styles */
.banner {
  background-color: var(--genics-dark-blue);
  height: 100px;
  min-height: 100px;
  box-shadow: 0 0 20px 0 var(--genics-purple-fade),
    0 5px 5px 0 var(--genics-purple-fade);
}

/* Error Section Styles */
.errors {
  font-family: "Roboto", sans-serif;
  width: 100%;
  text-align: center;
  padding-top: 150px;
  color: var(--genics-dark-blue);
}

/* SideNav */
.dynamicNavOptions {
  width: var(--side-nav-width);
}

.topNavOptions {
  border-top: #ffffff;
  border-top: 2px solid gray;
}

.navButton {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  background: var(--genics-dark-blue);
  width: 170px;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  padding-left: 20px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 0.4rem;
  text-align: Left;
}

.toggleSideNavButton {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  background: var(--genics-dark-blue);
  width: 25px;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  padding-left: 10px;
  color: #ffffff;
  font-size: 30px;
  border-radius: 0.4rem;
  text-align: Left;
}

.navButton:hover,
.toggleSideNavButton:hover {
  cursor: pointer;
  background: var(--genics-light-blue);
  color: var(--genics-purple);
}

.dashFlex {
  text-align: center;
}

.dashListItem {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.dashListItemButtonsFlex {
  display: flex;
  flex-direction: row;
}

.dashListItemDataRow {
  display: flex;
  flex-direction: row;
}

.dashTextListItemDataRow {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
}

.dashTestEntryLabelValuePair {
  display: flex;
  flex-direction: row;
}

.dashTestEntryLabel {
  padding-right: 10px;
}

.dashLabel {
  min-width: 300px;
}

.dashTitle {
  margin: 50px;
}

@media only screen and (min-width: 800px) {
  /* styles for minimum screen width of 800px (Desktop) */

  .logoutButton {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  /* Banner Styles*/
  .bannerLogo {
    color: white;
    text-align: left;
    padding-left: 15px;
    width: 200px;
    transform: translate(0%, 40%);
  }

  /* Input Form Styles*/
  .inputForm {
    margin: 100px;
    background: #ffffff;
    max-width: 360px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 var(--genics-purple-fade),
      0 5px 5px 0 var(--genics-purple-fade);
  }

  .inputFormFlex {
    display: flex;
    justify-content: space-around;
  }

  .formCaption {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 800px) {
  /* styles for max screen width of 800px (Mobile) */

  .inputForm {
    background: #ffffff;
    max-width: 250px;
    padding: 45px;
    margin-top: 100px;
    text-align: center;
    box-shadow: 0 0 20px 0 var(--genics-purple-fade),
      0 5px 5px 0 var(--genics-purple-fade);
  }

  .inputFormFlex {
    display: flex;
    justify-content: space-around;
  }

  .formCaption {
    padding-bottom: 30px;
  }

  .bannerLogo {
    color: white;
    text-align: center;
    padding-left: 15px;
    width: 200px;
    transform: translate(0%, 40%);
  }
}

@media print {
  printButton {
    display: none;
  }
}
