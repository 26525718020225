.skill-cards-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    width: 100%;
    padding-left: 200px;
}

.skill-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 1400px;
  padding: 15px;
}

.skill-card:hover {
    transform: translateY(-5px);
}

.skill-card-image {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
}

.skill-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
}

.skill-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0px;
    text-align: left;
    margin-top: 0;
}

.skill-card p {
    font-size: .9rem;
    color: #555;
    text-align: left;
    margin-top: 0;
}

.skill-card-buttons {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    margin-bottom: 20px;
}

.view-button {
    background-color: transparent;
    color: #000;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 100;
}

.edit-button {
    background-color: #ddd;
    color: #000;
    border: 1px solid #000;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 100;
}

.view-button:hover {
    text-decoration: underline;
}

.edit-button:hover {
    background-color: #eee;
}
